<template>
    <div>
        <v-row justify="center">
            <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="skill" placeholder="Enter your skill" required style="border-color: #fff;"></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
                <v-text-field v-model="location" placeholder="Where?" required style="border-color: #fff;"></v-text-field>
            </v-col>
        </v-row>
        <v-col cols="12" sm="12" md="12">
            <center><v-btn rounded class="button" @click="careerJetApi" :loading="fetch_loader">Fetch</v-btn></center>
        </v-col>
        <v-row dense>
            <v-col v-for="(job,index) in jobs" :key="index" cols="12" sm="4" md="4">
                <v-card :elevation="6" id="card-hover" fill-height style="margin: 8px; height: 100%;">
                    <v-col cols="12" sm="12" md="12">
                        <v-col cols="12" sm="12" md="12" class="p-0">
                           <center> <strong>{{job.company}}</strong> </center>
                        </v-col>
                         <v-divider></v-divider>
                        <v-col cols="12" sm="12" md="12">
                            <center>
                                <strong>Looking For{{job.title}}<br /></strong>
                            </center>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <center><i class="fa fa-map-marker mr-2" aria-hidden="true"></i>{{job.locations}}</center>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="p-0" v-if="job.salary">
                            <center>Salary : {{job.salary}}</center>
                        </v-col>
                        Date{{job.date}}<br />
                        <v-col cols="12" sm="12" md="12">
                            <center><span v-html="job.description"></span></center>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-if="job.site">
                          <center>Site : {{job.site}}</center>
                        </v-col>
                        <v-divider></v-divider>
                        <v-row justify="center">
                            <v-btn rounded class="button"> <a :href="job.url" target="_blank" class="link">Learn More & Apply</a><br /> </v-btn>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <!-- Jobs:{{jobs}} -->
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            location: "Pune",
            skill: "JAVA",
            jobs: null,
            fetch_loader: false,
        }),
        methods: {
            showsnackbar(msg, color, to) {
                this.$store.dispatch("setSnackbar", {
                    showing: true,
                    timeout: to,
                    color: color,
                    text: msg,
                });
            },
            onerror(error) {
                let msg = "";
                if (error.response) {
                    msg = "Something went wrong.";
                } else if (error.request) {
                    msg = "Please try again.";
                } else {
                    msg = "Un-expected error.";
                }
                this.showsnackbar(msg, "error", 5000);
            },
            careerJetApi() {
                const formData = {
                    location: this.location,
                    skill: this.skill,
                };
                this.fetch_loader = true;
                axios
                    .post("/career/careerJetApi", formData)
                    .then((res) => {
                        //console.log(res.data);
                        if (res.data.flag == "YES") {
                            this.jobs = res.data.jobs;
                        } else {
                            this.showsnackbar(res.data.snackbar, "error", 5000);
                        }
                    })
                    .catch((error) => {
                        this.onerror(error);
                        this.fetch_loader = false;
                    })
                    .finally(() => {
                        this.fetch_loader = false;
                    });
            },
        },
    };
</script>

<style scoped>
    .button {
        border: 2px solid #424093 !important;
        color: #424093;
        background: transparent !important;
        border-radius: 50px;
        margin: 0.5rem !important;
    }
    .button:hover {
        color: white;
        background: #424093 !important;
    }
    .link {
        color: black;
    }
    .link:hover {
        color: white;
    }
    .card {
       color: white;
        background: #424093 !important;
    }
</style>
